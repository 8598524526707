var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          { staticClass: "card card-custom gutter-b bg-white border-0" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "ol",
                { staticClass: "dd-list" },
                [
                  _c(
                    "draggable",
                    {
                      attrs: { group: _vm.elements, draggable: ".moving-card" },
                      on: {
                        start: function ($event) {
                          _vm.drag = true
                        },
                        end: function ($event) {
                          _vm.drag = false
                        },
                      },
                      model: {
                        value: _vm.elements,
                        callback: function ($$v) {
                          _vm.elements = $$v
                        },
                        expression: "elements",
                      },
                    },
                    _vm._l(_vm.elements, function (element, index) {
                      return _c(
                        "li",
                        {
                          key: element.id,
                          staticClass: "dd-item",
                          class: element.class,
                          staticStyle: { cursor: "pointer" },
                        },
                        [
                          _c("div", { staticClass: "dd-handle" }, [
                            _vm._v(_vm._s(element.name)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "inner-content" }, [
                            element.multiple == true
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    staticStyle: {
                                      "margin-right": "30px !important",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleModal(
                                          element.name,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Choose")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "custom-control switch custom-switch-info custom-switch custom-control-inline mr-0",
                              },
                              [
                                _c("input", {
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    type: "checkbox",
                                    id: "customSwitchcolor" + element.id,
                                  },
                                  domProps: {
                                    checked: element.display == true,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.toggleDisplay(index)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", {
                                  staticClass: "custom-control-label mr-1",
                                  staticStyle: {
                                    "vertical-align": "middle !important",
                                  },
                                  attrs: {
                                    for: "customSwitchcolor" + element.id,
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("img", { attrs: { src: element.image } }),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            staticStyle: { float: "right" },
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                return _vm.updateSetting()
              },
            },
          },
          [_vm._v("Update Home Page Builder Settings")]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade text-left",
        class: { show: _vm.toggleModel },
        staticStyle: { overflow: "auto" },
        style: [
          _vm.toggleModel
            ? { display: "block !important" }
            : { display: "none" },
        ],
        attrs: {
          id: "imagepopup",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "myModalLabel1",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                { staticClass: "modal-body" },
                _vm._l(
                  _vm.modelRenderImagesArray,
                  function (modelRenderImage, index) {
                    return _c("div", { staticStyle: { width: "100%" } }, [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        style:
                          _vm.selectedItem == modelRenderImage[1] ||
                          modelRenderImage[2] == "selected"
                            ? "border:4px solid green"
                            : "border:4px solid #E8E8E8",
                        attrs: { src: modelRenderImage[1] },
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem(
                              modelRenderImage[1],
                              modelRenderImage[0],
                              index
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("hr"),
                    ])
                  }
                ),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleModal()
                      },
                    },
                  },
                  [_c("span", {}, [_vm._v("Close")])]
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h3",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("Home Page Builder\r\n                    ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }