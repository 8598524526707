var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group row" },
    [
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Website Name")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.site_name,
              expression: "webgeneral.site_name",
            },
          ],
          ref: "site_name",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.webgeneral.site_name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "site_name", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Website Logo")]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.toggleImageSelect()
                },
              },
            },
            [_vm._v("Select Logo from gallary")]
          ),
          _vm._v(" "),
          _c(
            "small",
            { staticClass: "form-text text-muted", attrs: { id: "textHelp" } },
            [_vm._v("Select Logo from gallary.")]
          ),
          _vm._v(" "),
          _vm.errors.has("gallary_id")
            ? _c("small", {
                staticClass: "form-text text-danger",
                domProps: { textContent: _vm._s(_vm.errors.get("gallary_id")) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.webgeneral.site_logo != ""
            ? _c("img", {
                staticStyle: { width: "100px", height: "100px" },
                attrs: { src: _vm.webgeneral.site_logo },
              })
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("FavIcon Logo")]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.toggleImageSelectIcon()
                },
              },
            },
            [_vm._v("Select Favicon from gallary.")]
          ),
          _vm._v(" "),
          _c(
            "small",
            { staticClass: "form-text text-muted", attrs: { id: "textHelp" } },
            [_vm._v("Select Favicon from gallary.")]
          ),
          _vm._v(" "),
          _vm.errors.has("icon")
            ? _c("small", {
                staticClass: "form-text text-danger",
                domProps: { textContent: _vm._s(_vm.errors.get("icon")) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.webgeneral.favicon != ""
            ? _c("img", {
                staticStyle: { width: "100px", height: "100px" },
                attrs: { src: _vm.webgeneral.favicon },
              })
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Facebook URL")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.facebook_url,
              expression: "webgeneral.facebook_url",
            },
          ],
          ref: "facebook_url",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.webgeneral.facebook_url },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "facebook_url", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Google URL")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.google_url,
              expression: "webgeneral.google_url",
            },
          ],
          ref: "google_url",
          staticClass: "form-control border-dark",
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.webgeneral.google_url },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "google_url", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Twitter URL")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.twitter_url,
              expression: "webgeneral.twitter_url",
            },
          ],
          ref: "twitter_url",
          staticClass: "form-control border-dark",
          attrs: { type: "email", placeholder: "" },
          domProps: { value: _vm.webgeneral.twitter_url },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "twitter_url", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Linked URL")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.linkedin_url,
              expression: "webgeneral.linkedin_url",
            },
          ],
          ref: "linkedin_url",
          staticClass: "form-control border-dark",
          attrs: { type: "email", placeholder: "" },
          domProps: { value: _vm.webgeneral.linkedin_url },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "linkedin_url", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Instagram URL")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.instagram_url,
              expression: "webgeneral.instagram_url",
            },
          ],
          ref: "instagram_url",
          staticClass: "form-control border-dark",
          attrs: { type: "email", placeholder: "" },
          domProps: { value: _vm.webgeneral.instagram_url },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "instagram_url", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Allow Cookies")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.webgeneral.allow_cookies,
              expression: "webgeneral.allow_cookies",
            },
          ],
          ref: "allow_cookies",
          staticClass: "form-control border-dark",
          attrs: { type: "email", placeholder: "" },
          domProps: { value: _vm.webgeneral.allow_cookies },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.webgeneral, "allow_cookies", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("br"),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                return _vm.updateSetting()
              },
            },
          },
          [_vm._v("Submit")]
        ),
      ]),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal },
        on: {
          toggleImageSelect: _vm.toggleImageSelect,
          setImage: _vm.setImage,
        },
      }),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModalIcon },
        on: {
          toggleImageSelect: _vm.toggleImageSelectIcon,
          setImage: _vm.setIcon,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }