var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card card-custom gutter-b bg-transparent shadow-none border-0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header align-items-center border-bottom-dark px-0",
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "icons d-flex" },
                          [
                            _vm.$parent.permissions.includes("quotation-manage")
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "btn ml-2 p-0 kt_notes_panel_toggle",
                                    attrs: {
                                      to: "/admin/add-quotation",
                                      "data-toggle": "tooltip",
                                      title: "",
                                      "data-placement": "right",
                                      "data-original-title":
                                        "Check out more demos",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "bg-secondary h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center rounded-circle shadow-sm",
                                        on: {
                                          click: function ($event) {
                                            _vm.display_form = !_vm.display_form
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-plus white",
                                            attrs: {
                                              width: "25px",
                                              height: "25px",
                                              viewBox: "0 0 16 16",
                                              fill: "currentColor",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "table-responsive",
                            attrs: { id: "printableTable" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dataTables_wrapper no-footer",
                                attrs: { id: "purchase_wrapper" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_length",
                                    attrs: { id: "purchase_length" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Show \r\n                                            "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.limit,
                                              expression: "limit",
                                            },
                                          ],
                                          attrs: {
                                            name: "purchase_length",
                                            "aria-controls": "purchase",
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.limit = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function ($event) {
                                                return _vm.fetchQuotations()
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "25" } },
                                            [_vm._v("25")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "50" } },
                                            [_vm._v("50")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "200" } },
                                            [_vm._v("200")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "500" } },
                                            [_vm._v("500")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1000" } },
                                            [_vm._v("1000")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" entries"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "dataTables_filter",
                                  attrs: { id: "purchase_filter" },
                                }),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "display dataTable no-footer",
                                    attrs: { id: "purchase", purchase: "grid" },
                                  },
                                  [
                                    _c("thead", { staticClass: "text-body" }, [
                                      _c("tr", { attrs: { purchase: "row" } }, [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "id"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "id"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "31.25px" },
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls": "purchase",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sorting("id")
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                                ID\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                               Type\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                                Supplier/Customer\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                                Warehouse\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                                Grand Total " +
                                                _vm._s(_vm.getCurrencyTitle()) +
                                                "\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                                                View\r\n                                                            "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass: "kt-table-tbody text-dark",
                                      },
                                      _vm._l(
                                        _vm.quotations,
                                        function (quotation) {
                                          return _c(
                                            "tr",
                                            {
                                              key: quotation.id,
                                              staticClass:
                                                "kt-table-row kt-table-row-level-0 odd",
                                              attrs: { purchase: "row" },
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "sorting_1" },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to:
                                                          "/admin/quotation-detail/" +
                                                          quotation.id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\r\n                                                                    " +
                                                          _vm._s(quotation.id) +
                                                          "\r\n                                                                "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(quotation.type) +
                                                    "\r\n                                                            "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(
                                                      quotation.type == "sale"
                                                        ? quotation.customer
                                                            .customer_first_name +
                                                            " " +
                                                            quotation.customer
                                                              .customer_last_name
                                                        : quotation.supplier
                                                            .supplier_first_name +
                                                            " " +
                                                            quotation.supplier
                                                              .supplier_last_name
                                                    ) +
                                                    "\r\n                                                            "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(
                                                      quotation.warehouse !=
                                                        null
                                                        ? quotation.warehouse
                                                            .warehouse_name
                                                        : ""
                                                    ) +
                                                    "\r\n                                                            "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(
                                                      quotation.grand_total
                                                    ) +
                                                    "\r\n                                                            "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                { staticClass: "sorting_1" },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to:
                                                          "/admin/quotation-detail/" +
                                                          quotation.id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\r\n                                                                    View\r\n                                                                "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "pagination pagination-sm m-0 float-right",
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.prev_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchQuotations(
                                                  _vm.pagination.prev_page_url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Previous")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "disabled" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "page-link text-dark",
                                          attrs: { href: "#" },
                                        },
                                        [
                                          _vm._v(
                                            "Page " +
                                              _vm._s(
                                                _vm.pagination.current_page
                                              ) +
                                              " of " +
                                              _vm._s(_vm.pagination.last_page)
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "page-item",
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.next_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchQuotations(
                                                  _vm.pagination.next_page_url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Next")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-title mb-0" }, [
      _c("h3", { staticClass: "card-label mb-0 font-weight-bold text-body" }, [
        _vm._v(
          "\r\n                                            Quotation List\r\n                                        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }