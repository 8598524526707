var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card card-custom gutter-b bg-transparent shadow-none border-0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header align-items-center border-bottom-dark px-0",
                      },
                      [
                        _c("div", { staticClass: "card-title mb-0" }, [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "card-label mb-0 font-weight-bold text-body",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$route.params.id ? "Edit" : "Add"
                                  ) +
                                  " Delivery Boy\n                      "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "icons d-flex" }),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("First Name"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.first_name,
                                  expression: "deliver_boy.first_name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.first_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "first_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("first_name")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("first_name")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Last Name"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.last_name,
                                  expression: "deliver_boy.last_name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.last_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "last_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("last_name")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("last_name")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Phone Number"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.phone_number,
                                  expression: "deliver_boy.phone_number",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "tel", name: "mobilenumber" },
                              domProps: { value: _vm.deliver_boy.phone_number },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "phone_number",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("phone_number")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("phone_number")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Avatar"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "fieldset",
                              {
                                staticClass:
                                  "form-group mb-3 border-dark rounded p-1",
                              },
                              [
                                _c("input", {
                                  staticClass: "d-block",
                                  attrs: {
                                    type: "file",
                                    id: "img1",
                                    name: "img",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.uploadAvatar($event)
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.errors.has("avatar")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("avatar")
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.edit
                              ? _c("img", {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    src: "/delivery/" + _vm.selectedAvatar,
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Date of Birth"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.dob,
                                  expression: "deliver_boy.dob",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "date",
                                id: "birthday",
                                name: "birthday",
                              },
                              domProps: { value: _vm.deliver_boy.dob },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "dob",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("dob")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(_vm.errors.get("dob")),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Blood Group"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.deliver_boy.blood_group,
                                    expression: "deliver_boy.blood_group",
                                  },
                                ],
                                staticClass: "bloodgroup w-100 mb-3 h-30px",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.deliver_boy,
                                      "blood_group",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v("A +ve"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v("B +ve"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v("AB +ve"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v("A -ve"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.errors.has("blood_group")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("blood_group")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Commission"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.commission,
                                  expression: "deliver_boy.commission",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                value: "0",
                                name: "text",
                              },
                              domProps: { value: _vm.deliver_boy.commission },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "commission",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("commission")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("commission")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Email Address"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.email,
                                  expression: "deliver_boy.email",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "email", name: "text" },
                              domProps: { value: _vm.deliver_boy.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("email")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("email")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Pin Code"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.pin_code,
                                  expression: "deliver_boy.pin_code",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.pin_code },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "pin_code",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("pin_code")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("pin_code")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Status"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.deliver_boy.status,
                                    expression: "deliver_boy.status",
                                  },
                                ],
                                staticClass: "status w-100 mb-3 h-30px",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.deliver_boy,
                                      "status",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v("Active"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "2" } }, [
                                  _vm._v("In active"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.errors.has("status")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("status")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Availability Status"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.deliver_boy.availability_status,
                                    expression:
                                      "deliver_boy.availability_status",
                                  },
                                ],
                                staticClass: "availability a w-100 mb-3 h-30px",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.deliver_boy,
                                      "availability_status",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v("Online"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "2" } }, [
                                  _vm._v("Offline"),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.errors.has("availability_status")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.get("availability_status")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v(" Address"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.address,
                                  expression: "deliver_boy.address",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.address },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "address",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("address")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("address")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("City"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.city,
                                  expression: "deliver_boy.city",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.city },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "city",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("city")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(_vm.errors.get("city")),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Country"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.deliver_boy.country,
                                    expression: "deliver_boy.country",
                                  },
                                ],
                                staticClass:
                                  "js-example-basic-single js-states form-control bg-transparent",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.deliver_boy,
                                        "country",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.fetchStatesByCountryID(
                                        _vm.deliver_boy.country
                                      )
                                    },
                                  ],
                                },
                              },
                              _vm._l(_vm.countries, function (country) {
                                return _c(
                                  "option",
                                  {
                                    key: country.country_id,
                                    domProps: {
                                      value: country.country_id,
                                      selected:
                                        _vm.deliver_boy.country ==
                                        country.country_id,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(country.country_name)
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm.errors.has("country")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("country")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Zip Code"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.zip_code,
                                  expression: "deliver_boy.zip_code",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.zip_code },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "zip_code",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("zip_code")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("zip_code")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v(" State"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.deliver_boy.state,
                                    expression: "deliver_boy.state",
                                  },
                                ],
                                staticClass:
                                  "js-example-basic-single js-states form-control bg-transparent",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.deliver_boy,
                                      "state",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.states, function (state) {
                                return _c(
                                  "option",
                                  {
                                    key: state.id,
                                    domProps: {
                                      value: state.id,
                                      selected:
                                        _vm.deliver_boy.state == state.id,
                                    },
                                  },
                                  [_vm._v(_vm._s(state.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm.errors.has("state")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("state")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Vehicle Name"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.vehicle_name,
                                  expression: "deliver_boy.vehicle_name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.vehicle_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "vehicle_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("vehicle_name")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("vehicle_name")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Owner Name"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.owner_name,
                                  expression: "deliver_boy.owner_name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.owner_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "owner_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("owner_name")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("owner_name")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Vehicle Color"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.vehicle_color,
                                  expression: "deliver_boy.vehicle_color",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: {
                                value: _vm.deliver_boy.vehicle_color,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "vehicle_color",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("vehicle_color")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("vehicle_color")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Vehicle Registration#"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.deliver_boy.vehicle_registration_no,
                                  expression:
                                    "deliver_boy.vehicle_registration_no",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: {
                                value: _vm.deliver_boy.vehicle_registration_no,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "vehicle_registration_no",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("vehicle_registration_no")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("vehicle_registration_no")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Vehicle Details"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.vehicle_details,
                                  expression: "deliver_boy.vehicle_details",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: {
                                value: _vm.deliver_boy.vehicle_details,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "vehicle_details",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("vehicle_details")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("vehicle_details")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Driving License"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "fieldset",
                              {
                                staticClass:
                                  "form-group mb-3 border-dark rounded p-1",
                              },
                              [
                                _c("input", {
                                  staticClass: "d-block",
                                  attrs: {
                                    type: "file",
                                    id: "img1",
                                    name: "img",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.uploadDrivingLicense($event)
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.edit
                              ? _c("img", {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    src:
                                      "/delivery/" +
                                      _vm.selectedDrivingLicenseNo,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.errors.has("driving_license_no")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("driving_license_no")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Vehicle RC Book"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "fieldset",
                              {
                                staticClass:
                                  "form-group mb-3 border-dark rounded p-1",
                              },
                              [
                                _c("input", {
                                  staticClass: "d-block",
                                  attrs: {
                                    type: "file",
                                    id: "img1",
                                    name: "img",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.uploadRcBooK($event)
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.edit
                              ? _c("img", {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    src:
                                      "/delivery/" +
                                      _vm.selectedVehicleRcBookNo,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.errors.has("vehicle_rc_book_no")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("vehicle_rc_book_no")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _vm._m(4),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Account Name"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.account_name,
                                  expression: "deliver_boy.account_name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.account_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "account_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("account_name")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("account_name")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Account Number"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.account_number,
                                  expression: "deliver_boy.account_number",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: {
                                value: _vm.deliver_boy.account_number,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "account_number",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("account_number")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("account_number")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Gpay Number"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.gpay_number,
                                  expression: "deliver_boy.gpay_number",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.gpay_number },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "gpay_number",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("gpay_number")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("gpay_number")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Bank Address"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.bank_address,
                                  expression: "deliver_boy.bank_address",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.bank_address },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "bank_address",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("bank_address")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("bank_address")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("IFSC Code"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.ifsc_code,
                                  expression: "deliver_boy.ifsc_code",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.ifsc_code },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "ifsc_code",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("ifsc_code")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("ifsc_code")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-lg-4" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { staticClass: "text-dark" }, [
                              _vm._v("Branch Name"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.deliver_boy.branch_name,
                                  expression: "deliver_boy.branch_name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "text" },
                              domProps: { value: _vm.deliver_boy.branch_name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.deliver_boy,
                                    "branch_name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("branch_name")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("branch_name")
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 d-flex justify-content-between" },
                [
                  _c(
                    "a",
                    { staticClass: "btn btn-dark", attrs: { href: "#" } },
                    [_vm._v("Back")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary white",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.saveUpdate()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                  Add Delivery Boy\n                "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h4",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [
            _vm._v(
              "\n                      Delivery Boy Info\n                    "
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h4",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("\n                      Login Info\n                    ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h4",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("\n                      Address Info\n                    ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h4",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("\n                      Vehicle Info\n                    ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h4",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("\n                      Bank Info\n                    ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }